import React, { useContext, useEffect } from 'react';
import { gsap } from 'gsap';
import { AppContext } from '../../../helpers/context';
import { isDesktop, isTablet } from '../../../helpers/constants';
import { ReactComponent as AncorLogo } from '../../../assets/brands/Ancor.svg';
import pgLogo from '../../../assets/brands/P&G.png';
import { ReactComponent as OpenLogo } from '../../../assets/brands/OpenAgency.svg';
import jtiLogo from '../../../assets/brands/JTI.png';
import { ReactComponent as MarsLogo } from '../../../assets/brands/Mars.svg';
import { ReactComponent as MiratorgLogo } from '../../../assets/brands/Miratorg.svg';
import { ReactComponent as ChapitaLogo } from '../../../assets/brands/Chapita.svg';
import { ReactComponent as MagnitLogo } from '../../../assets/brands/Magnit.svg';
import styles from './trustUs.module.scss';

const TrustUs = React.memo(({ full }) => {
    const extra = full ? '-full' : '';
    const loaded = useContext(AppContext);

    useEffect(() => {
        if (loaded) {
            show();
        }
    }, [loaded]);

    const show = () => {
        if (isTablet) {
            gsap.utils.toArray(`.brand-anim${extra} span`).forEach((elem, i) => {
                gsap.from(elem, {
                    scrollTrigger: {
                        trigger: `.brand-anim${extra}`,
                        start: 'bottom-=50 bottom',
                    },
                    opacity: 0,
                    y: 100,
                    delay: (i ?? 0.01) * 0.2,
                    duration: 1,
                });
            });
        } else {
            // gsap.utils.toArray(`.brand-anim${extra} div span`).forEach((elem, i) => {
            //     gsap.from(elem, {
            //         scrollTrigger: {
            //             trigger: elem,
            //             start: 'bottom-=70 bottom',
            //         },
            //         opacity: 0,
            //         y: 100,
            //         delay: (i ?? 0.01) * 0.03,
            //         duration: 1,
            //     });
            // });
        }

        if (isTablet) {
            gsap.utils.toArray(`.hr-anim-trust${extra}`).forEach((el, idx, array) => {
                gsap.set(el, { width: '100%' });
                gsap.from(
                    el,
                    {
                        scrollTrigger: {
                            trigger: el,
                            start: 'bottom bottom',
                        },
                        width: '0%',
                        opacity: 0,
                        delay: 0.3,
                        duration: 2.5,
                        ease: idx === array.length - 1 ? 'power1.out' : 'power3.out',
                    });
            });
        }
    };

    return (
        <section id={full ? 'clients-trust' : ''} className={`${styles.trustUsMain} ${full ? styles.sepBlock : ''}`}>
            {full
                ? (
                    <h2 className={`${!isDesktop ? 'fade-in' : ''}`}>
                        <span className="title-split-line">
                            Нам доверяют
                        </span>
                    </h2>
                ) : <h3 className="fade-in">Нам доверяют</h3>
            }
            <hr id="first-hr-trust" className={`hr-anim-trust${extra} ${styles.trustUsHr}`} />
            <div className={`${styles.brandsWrapper} brand-anim${extra}`}>
                <span>
                    <AncorLogo />
                </span>
                <span>
                    <img className={styles.brandsPg} src={pgLogo} alt="PG" />
                </span>
                <span>
                    <OpenLogo />
                </span>
                <span>
                    <img className={styles.brandsJti} src={jtiLogo} alt="JTI" />
                </span>
                <span>
                    <MarsLogo />
                </span>
                <span>
                    <MiratorgLogo />
                </span>
                <span>
                    <ChapitaLogo />
                </span>
                <span>
                    <MagnitLogo />
                </span>
            </div>
            <div className={`${styles.mobileBrands} brand-anim${extra}`}>
                <div className={styles.mobileBrandsRows}>
                    <span>
                        <AncorLogo />
                    </span>
                    <span>
                        <img className={styles.brandsPg} src={pgLogo} alt="PG" />
                    </span>
                </div>
                <div className={styles.mobileBrandsRows}>
                    <span>
                        <OpenLogo className={styles.openLogo}/>
                    </span>
                    <span>
                        <img className={styles.brandsJti} src={jtiLogo} alt="JTI" />
                    </span>
                </div>
                <div className={styles.mobileBrandsRows}>
                    <span>
                        <MiratorgLogo />
                    </span>
                    <span>
                        <MarsLogo />
                    </span>
                </div>
                <div className={styles.mobileBrandsRows}>
                    <span>
                        <MagnitLogo />
                    </span>
                    <span>
                        <ChapitaLogo />
                    </span>
                </div>
            </div>
            <hr className={`hr-anim-trust${extra} ${styles.trustUsHr}`} />
        </section>
    )
});

export default TrustUs;