import React, { useState } from 'react';
import { useWindowSize } from '../../../helpers/resize';
import { isDesktop, TABLET_PX } from '../../../helpers/constants';
import styles from './pricing.module.scss';

const Pricing = React.memo(({ onClickAction }) => {
    const [tab, setTab] = useState('free');
    const [x] = useWindowSize();
    const showFullTable = x >= TABLET_PX;

    const getStyleCol = () => {
        switch (tab) {
            case 'free':
                return styles.showFree;
            case 'cloud':
                return styles.showCloud;
            case 'enterprise':
                return styles.showEnterprise;
            default:
                return '';
        }
    };

    return (
        <section id="pricing" className={styles.pricingMain}>
            <div className={styles.pricingInner}>
                <h2 className={`title ${styles.sectionTitle} ${isDesktop ? '' : 'fade-in'}`}>
                    <span className="title-split-line">Тарифы и</span>
                    <span className="title-split-line">условия</span>
                </h2>
                {!showFullTable && (
                    <div className={styles.btnBlock}>
                        <div
                            onClick={() => setTab('free')}
                            className={`${styles.btn} ${tab === 'free' ? styles.active : ''}`}
                        >
                            Free
                        </div>
                        <div
                            onClick={() => setTab('cloud')}
                            className={`${styles.btn} ${tab === 'cloud' ? styles.active : ''}`}
                        >
                            Cloud
                        </div>
                        <div
                            onClick={() => setTab('enterprise')}
                            className={`${styles.btn} ${tab === 'enterprise' ? styles.active : ''}`}
                        >
                            Enterprise
                        </div>
                    </div>
                )}
                <div className={styles.tableBlock}>
                    <table>
                        <thead>
                        {showFullTable && (
                            <tr>
                                <th/>
                                <th>Free</th>
                                <th>Cloud</th>
                                <th>Enterprise</th>
                            </tr>
                        )}
                        </thead>
                        <tbody className={showFullTable ? '' : getStyleCol()}>
                            <tr>
                                <td>Цена</td>
                                <td>Бесплатно</td>
                                <td>6 руб./визит</td>
                                <td className={styles.tdLink}>
                                    <span onClick={onClickAction}>Запросить стоимость*</span>
                                </td>
                            </tr>
                            <tr>
                                <td>Размещение</td>
                                <td>Облако WODO</td>
                                <td>Облако WODO</td>
                                <td>Собственная инфраструктура</td>
                            </tr>
                            <tr>
                                <td>Визиты</td>
                                <td>До 5 тыс. визитов в месяц</td>
                                <td>Без ограничений</td>
                                <td>Без ограничений</td>
                            </tr>
                            <tr>
                                <td>Хранение истории</td>
                                <td>1 месяц</td>
                                <td>6 месяцев</td>
                                <td>Без ограничений</td>
                            </tr>
                            <tr>
                                <td>Фотографий на 1 визит</td>
                                <td>До 15 фото</td>
                                <td>До 50 фото</td>
                                <td>Без ограничений</td>
                            </tr>
                            <tr>
                                <td>Поддержка</td>
                                <td>—</td>
                                <td>E-mail, WhatsApp</td>
                                <td>Без ограничений**</td>
                            </tr>
                            <tr>
                                <td>Доработка продукта</td>
                                <td>—</td>
                                <td>Почасовая оплата</td>
                                <td>Без ограничений**</td>
                            </tr>
                            <tr>
                                <td>Персональный консультант</td>
                                <td>Доп. оплата</td>
                                <td>10 часов/месяц</td>
                                <td>Бесплатно на период настройки системы</td>
                            </tr>
                            <tr>
                                <td>Логирование изменений и действий всех пользователей</td>
                                <td>+</td>
                                <td>+</td>
                                <td>+</td>
                            </tr>
                            <tr>
                                <td>Защита от Fake-визитов</td>
                                <td>+</td>
                                <td>+</td>
                                <td>+</td>
                            </tr>
                            <tr>
                                <td>Лицензии</td>
                                <td>Бесплатный доступ сроком до 6 месяцев</td>
                                <td>Учет лицензий по факту использования за месяц***</td>
                                <td>Бессрочный доступ с правами на исходный код</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className={styles.tableAnnotations}>
                    <p>
                        * Единовременный платеж за неисключительную лицензию с исходным кодом
                        <br/>
                        ** На тарифе Enterprise возможна самостоятельная разработка и поддержка системы
                        или привлечение любых подрядчиков. Тарифы на привлечение команды WODO согласовываются индивидуально.
                        <br/>
                        *** Стоимость аренды системы зависит от количества сделанных визитов: Один визит — 6 рублей,
                        если визитов в месяц совершается более 10 000.
                        <br/>
                        Фиксированные ежемесячный платеж — 10 000 рублей, если в месяц совершается менее 10 000 визитов.
                    </p>
                </div>
                <div className={styles.btnWrapper}>
                    <button className="btn btn-fill-right" onClick={onClickAction}>
                        Стать клиентом
                    </button>
                </div>
            </div>
        </section>
    )
});

export default Pricing;
