import React, { useContext, useEffect } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { AppContext } from '../../helpers/context';
import { fromPxMToInit, isDesktop } from '../../helpers/constants';
import { ReactComponent as WodoLogo } from '../../assets/wodoWhite.svg';
import { ReactComponent as RaketaLogo } from '../../assets/raketaLogo.svg';
import appStoreIcon from '../../assets/appStore.png';
import googlePlayIcon from '../../assets/googlePlay.png';
import styles from './footer.module.scss';

const Footer = React.memo(({ onClickAction }) => {
    const loaded = useContext(AppContext);

    useEffect(() => {
        if (loaded && isDesktop) {
            initAnimation();
        }
    }, [loaded, isDesktop]);

    const initAnimation = () => {
        ScrollTrigger.matchMedia({
            [fromPxMToInit]: function () {
                gsap.set('#footer-inner', { yPercent: -100 });
                const uncover = gsap.timeline({ paused: true });
                uncover.to('#footer-inner', { yPercent: 0, ease: 'none' });

                ScrollTrigger.create({
                    trigger: '#documents',
                    start: 'bottom+=190px bottom',
                    end: '+=100%',
                    animation: uncover,
                    scrub: true,
                });

                gsap.utils.toArray('.hr-anim-footer').forEach((el) => {
                    gsap.from(
                        el,
                        {
                            scrollTrigger: {
                                trigger: el,
                                start: 'bottom bottom',
                            },
                            width: '0%',
                            opacity: 0,
                            delay: 0.3,
                            duration: 2.5,
                            ease: 'power1.out',
                        });
                });
            },
            '(max-width: 1008px)': function() {
                gsap.set('#footer-inner', { yPercent: 0 });
                gsap.utils.toArray('.hr-anim-footer').forEach((el) => {
                    gsap.set(el, {
                        width: '100%',
                        opacity: 1,
                    })
                });
            }
        });
    };

    const openLink = (url) => {
        window.open(url, '_blank').focus();
    };

    const toTop = () => {
        gsap.to(window, { duration: 1, scrollTo: { y: 0, x: 0 }});
    };

    return (
        <footer id="footer" className={styles.footer}>
            <section id="footer-inner" className={styles.footerContainer}>
                <WodoLogo onClick={toTop} id="footer-logo" className={styles.wodoLogo} />
                <button className="btn-outline-reverse" onClick={onClickAction}>
                    Получить тестовый доступ
                </button>
                <div className={styles.footerInfo}>
                    <div className={styles.infoItem}>
                        <span className={styles.infoTitle}>Контакты</span>
                        <hr className={`hr-anim-footer ${styles.footerHr}`} />
                        <div className={styles.contactBlocks}>
                            <span>Адрес</span>
                            <span className={styles.contactBlocksText}>
                                ул. Кубанская набережная 39, офис Адмирал, г. Краснодар
                            </span>
                        </div>
                        <div className={styles.contactBlocks}>
                            <a href="mailto:support@mywodo.ru" className={styles.contactBlocksText}>
                                support@mywodo.ru
                            </a>
                        </div>
                    </div>
                    <div className={styles.infoItem}>
                        <span className={styles.infoTitle}>App WODO</span>
                        <hr className={`hr-anim-footer ${styles.footerHr}`} />
                        <span className={styles.infoText}>Скачать мобильное приложение</span>
                        <div className={styles.storesWrapper}>
                            <img
                                src={googlePlayIcon}
                                className={styles.googlePlayIcon}
                                onClick={() =>
                                    openLink('https://play.google.com/store/apps/details?id=com.wodomobile')
                                }
                                alt="googlePlayIcon"
                            />
                            <img
                                src={appStoreIcon}
                                className={styles.appStoreIcon}
                                onClick={() =>
                                    openLink('https://apps.apple.com/ru/app/wodo/id1464919898')
                                }
                                alt="appStoreIcon"
                            />
                        </div>
                    </div>
                    <div className={styles.infoItem}>
                        <span className={styles.infoTitle}>Разработчик</span>
                        <hr className={`hr-anim-footer ${styles.footerHr}`} />
                        <span className={styles.infoText}>Компания</span>
                        <RaketaLogo
                            className={styles.raketaLogo}
                            onClick={() =>
                                openLink('https://www.raketa.im/')
                            }
                        />
                    </div>
                </div>
            </section>
        </footer>
    )
});

export default Footer;