import React from 'react';
import styles from './textarea.module.scss';

const Textarea = ({ value, onChange, placeholder }) => {
    const onChangeLocal = (e) => {
        const val = e.target.value || '';
        onChange && onChange(val)
    };

    return (
        <div className={styles.textareaContainer}>
            <div className={styles.textareaWrapper}>
                <textarea
                    rows="4"
                    className={styles.textarea}
                    value={value || ''}
                    onChange={onChangeLocal}
                    placeholder={placeholder || ''}
                />
            </div>
        </div>
    )
};

export default Textarea;
