import React from 'react';
import { useWindowSize } from '../../../helpers/resize';
import { isDesktop, MOBILE_PX, TABLET_PX } from '../../../helpers/constants';
import team from '../../../assets/ourTeam.jpg';
import styles from './aboutUs.module.scss';

const AboutUs = React.memo(() => {
    const [x] = useWindowSize();
    const showTabletTitle = x >= MOBILE_PX && x < TABLET_PX;
    return (
        <section id="team" className={styles.aboutUsMain}>
            <div className={styles.aboutUsMainInner}>
                <div className={styles.leftBlock}>
                    <h2 className={`title ${styles.sectionTitle} ${isDesktop ? '' : 'fade-in'}`}>
                        {showTabletTitle
                            ? (
                                <>
                                    <span className="title-split-line">Разработчики с </span>
                                    <span className="title-split-line">экспертизой в ритейле</span>
                                </>
                            ) : (
                                <>
                                    <span className="title-split-line">Разработчики</span>
                                    <span className="title-split-line">с экспертизой</span>
                                    <span className="title-split-line">в ритейле</span>
                                </>
                            )}
                    </h2>
                    <p className="fade-in">
                        Мы — команда сильных разработчиков и системных аналитиков с большим опытом работы
                        в ритейле и знакомых с процессом изнутри. Поэтому продукт мы создавали с
                        полным пониманием процессов, проблем и запросов бизнеса. Но мы знаем, что
                        каждый бизнес уникален, поэтому помогаем внедрять в WODO новые решения под задачи
                        наших клиентов.
                    </p>
                </div>
                <div className={styles.parallaxWrapper}>
                    <img data-speed="auto" src={team} alt="about-us-team" />
                </div>
            </div>
        </section>
    )
});

export default AboutUs;
