import React from 'react';
import { isDesktop } from '../../../helpers/constants';
import styles from './requirements.module.scss';

const Requirements = () => {
    return (
        <section className={styles.requirementsMain}>
            <div className={styles.requirementsInner}>
                <h2 className={`title ${styles.sectionTitle} ${!isDesktop ? 'fade-in' : ''}`}>
                    <span className="title-split-line">Системные</span>
                    <span className="title-split-line">требования</span>
                </h2>
                <div className={styles.instructionBlock}>
                    <p className={styles.instructionText}>
                        Убедитесь, что ваш браузер подойдёт для работы веб-платформы WODO,
                        а OS телефонов или планшетов — для работы мобильного приложения.
                    </p>
                    <p>
                        <a
                            target="_blank"
                            rel="noreferrer"
                            href="https://app.mywodo.ru/help/40208457.html"
                            className={styles.instructionLink}
                        >
                            Инструкция к системным требованиям
                        </a>
                    </p>
                </div>
            </div>
            <div className={`${styles.ellipse} circle-path`} />
        </section>
    )
};

export default Requirements;
