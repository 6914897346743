import React, { useContext, useEffect, useState } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { useWindowSize } from '../../../helpers/resize';
import { AppContext } from '../../../helpers/context';
import { fromPxMToInit, TABLET_PX } from '../../../helpers/constants';
import { ReactComponent as CrossIcon } from '../../../assets/icons/cross.svg';
import { ReactComponent as CheckIcon } from '../../../assets/icons/blackCheck.svg';
import eyeIcon from '../../../assets/icons/eye.png';
import shops from '../../../assets/shops.jpg';
import manufacturer from '../../../assets/manufacturer.jpg';
import marketing from '../../../assets/marketing.jpg';
import styles from './wodoFor.module.scss';

const SCROLL_PHOTOS = ['#wodo-for-sec-1', '#wodo-for-sec-2', '#wodo-for-sec-3'];
const SCROLL_TEXT = ['#wodo-for-scroll-1', '#wodo-for-scroll-2', '#wodo-for-scroll-3'];

const WODO_FOR_DATA = [
    {
        id: 1,
        title: 'Торговым сетям',
        text: 'Ускоряет взаимодействие между магазином и головным офисом, помогает контролировать представленность и ' +
            'выкладку товара на магазинных полках.',
        img: shops,
        alt: 'shops',
        marks: [
            'Проведение ценового мониторинга конкуретов',
            'Контроль состояния оборудования и доп. мест продаж в магазинах',
            'Интеграция с системами распознавания для быстрого расчёта представленности товаров',
            'Контроль проведения промо-активностей',
        ]
    },
    {
        id: 2,
        title: 'Производителям',
        text: 'Автоматизирует анализ представленности товаров относительно конкурентов, помогает контролировать ' +
            'работу полевых сотрудников, оптимизирует работу мерчандайзера.',
        img: manufacturer,
        alt: 'manufacturer',
        marks: [
            'Контроль мерчандайзеров при визитах',
            'Предоставление нужной отчётности клиентам',
            'Формирование активностей под любые запросы клиентов',
        ]
    },
    {
        id: 3,
        title: 'Маркетинговым агентствам',
        text: 'Позволяет подстроиться под бизнес-процессы ваших клиентов с помощью создания любых активностей ' +
            'для визита в торговую точку.',
        img: marketing,
        alt: 'marketing',
        marks: [
            'Оперативное реагирование сотрудников на проблемы с товаром во всех точках',
            'Оперативное реагирование сотрудников на проблемы с товаром во всех точках',
            'Опросники для ускорения обновлений обстановки и оборудования в торговых точках при ' +
            'изменениях на уровне головного офиса',
            'Чек-листы для оперативного взаимодействие персонала магазина и сотрудников головного офиса',
        ]
    }
];

const SmallPopup = React.memo(({ el, togglePossible }) => (
    <div className={styles.gradientBlock}>
        <div className={styles.topTitle}>
            <span className={styles.itemTopTitle}>
                Возможности
            </span>
            <CrossIcon
                className={styles.itemTopTitleIcon}
                onClick={() => togglePossible(el.id)}
            />
        </div>
        <div className={styles.marksBlock}>
            {el.marks.map((m) => (
                <div className={styles.markText}>
                    <CheckIcon className={styles.checkIcon} />
                    <span>{m}</span>
                </div>
            ))}
        </div>
    </div>
));

const TextItem = React.memo(({ el, togglePossible }) => (
    <>
        <span className={styles.itemTitle}>{el.title}</span>
        <p className={styles.itemText}>{el.text}</p>
        <div
            className={styles.btnPossibilities}
            onClick={() => togglePossible(el.id)}
        >
            <span className={styles.btnPossibilitiesName}>
                Возможности
            </span>
            <img src={eyeIcon} alt="possibilities" />
        </div>
    </>
));


const WodoFor = React.memo(() => {
    const [x] = useWindowSize();
    const showScrollable = x >= TABLET_PX;
    const [opened, setOpened] = useState('');
    const loaded = useContext(AppContext);

    const obj = {
        scrollTrigger: {
            trigger: '#wodo-for-inner',
            start: 'top center-=138px',
            end: 'bottom bottom',
            pin: '#wodo-for-title',
            scrub: 2,
        },
    };

    useEffect(() => {
        if (loaded) {
            initAnimation();
        }
    }, [loaded]);

    const initAnimation = () => {
        ScrollTrigger.matchMedia({
            [fromPxMToInit]: function () {
                gsap.to('#wodo-for-inner', obj);

                SCROLL_PHOTOS.forEach((el, idx, arr) => {
                    gsap.timeline({
                        scrollTrigger: {
                            trigger: el,
                            start: 'top top',
                            end: 'bottom bottom',
                            pin: `${el} div`,
                            scrub: 2,
                        },
                    });

                    gsap.from(`${el}-1-1`, {
                        scrollTrigger: {
                            trigger: el,
                            start: 'top center',
                            end: '+=250',
                            scrub: 2,
                            toggleActions: 'restart pause reverse pause',
                        },
                        opacity: 0,
                        y: 200,
                    });

                    if (idx !== arr.length - 1) {
                        gsap.to(`${el}-1`, {
                            scrollTrigger: {
                                trigger: el,
                                start: 'center+=200 center',
                                end: '+=600',
                                scrub: 2,
                                toggleActions: 'restart pause reverse pause',
                            },
                            opacity: 0,
                        });
                    }
                });

                SCROLL_TEXT.forEach((el, idx, arr) => {
                    gsap.timeline({
                        scrollTrigger: {
                            trigger: el,
                            start: 'top top',
                            end: `bottom bottom${idx === arr.length - 1 ? '' : '+=300'}`,
                            pin: `${el} div`,
                            scrub: 2,
                        },
                    });

                    gsap.from(`${el}-1-1`, {
                        scrollTrigger: {
                            trigger: el,
                            start: 'top center',
                            end: '+=600',
                            scrub: 2,
                            toggleActions: 'restart pause reverse pause',
                        },
                        opacity: 0,
                        y: 600,
                    });

                    if (idx !== arr.length - 1) {
                        gsap.to(`${el}-1`, {
                            scrollTrigger: {
                                trigger: el,
                                start: 'center+=300 center',
                                end: '+=600',
                                scrub: 2,
                                toggleActions: 'restart pause reverse pause',
                            },
                            opacity: 0,
                        });
                    }
                });
            },
            '(max-width: 1008px)': function() {
                gsap.to('#wodo-for-inner', {
                    scrollTrigger: null,
                    overwrite: true
                });
            },
        });
    };

    const togglePossible = (id) => {
        if (opened === id) {
            return setOpened('');
        }
        setOpened(id);
    };

    return (
        <section id="wodo-for" className={styles.wodoFor}>
            <div id="wodo-for-inner" className={styles.wodoForInner}>
                {showScrollable ? (
                    <>
                        <h2 id="wodo-for-title" className={styles.sectionTitle}>
                            wodo подойдёт
                        </h2>
                        <div className={styles.scrollContentWrapper}>
                            <div className={styles.scrollContent}>
                                {WODO_FOR_DATA.map((el) => (
                                    <div
                                        key={`wodo-b-${el.id}`}
                                        id={`wodo-for-sec-${el.id}`}
                                        className={styles.animationWrapper}
                                    >
                                        <div id={`wodo-for-sec-${el.id}-1`} className={styles.itemsWrapper}>
                                            <div id={`wodo-for-sec-${el.id}-1-1`} className={styles.itemInner}>
                                                <img src={el.img} alt={el.alt} />
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className={styles.scrollContentWrapper}>
                            <div className={styles.scrollContent}>
                                {WODO_FOR_DATA.map((el) => (
                                    <div
                                        key={`wodo-s-${el.id}`}
                                        id={`wodo-for-scroll-${el.id}`}
                                        className={styles.animationWrapper}
                                    >
                                        <div id={`wodo-for-scroll-${el.id}-1`} className={styles.itemsWrapper}>
                                            <div id={`wodo-for-scroll-${el.id}-1-1`} className={styles.textItem}>
                                                {opened === el.id ? (
                                                    <SmallPopup el={el} togglePossible={togglePossible} />
                                                ) : (
                                                    <TextItem el={el} togglePossible={togglePossible} />
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </>
                ) : (
                    <>
                        <h2 className={`${styles.sectionTitle} fade-in`}>
                            wodo подойдёт
                        </h2>
                        <div className={styles.smallBlock}>
                            {WODO_FOR_DATA.map((el) => (
                                <div key={`wodo-for-small-${el.id}`} className={`${styles.blockItem} fade-in`}>
                                    <div className={styles.leftPart}>
                                        <img src={el.img} alt={el.alt} />
                                    </div>
                                    <div className={styles.rightPart}>
                                        <div className={styles.textItem}>
                                            {opened === el.id ? (
                                                <SmallPopup el={el} togglePossible={togglePossible} />
                                            ) : (
                                                <TextItem el={el} togglePossible={togglePossible} />
                                            )}
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </>
                )}
            </div>
        </section>
    )
});

export default WodoFor;
