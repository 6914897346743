import React, { useState } from 'react';
import { ReactComponent as PlusIcon } from '../../../assets/icons/plus.svg';
import { ReactComponent as MinusIcon } from '../../../assets/icons/minus.svg';
import { ReactComponent as CheckIcon } from '../../../assets/icons/blackCheck.svg';
import { isDesktop } from '../../../helpers/constants';
import styles from './platformFunc.module.scss';

const DATA_ARRAY = [
    {
        id: 1,
        title: 'В приложении',
        items: [
            'Визиты',
            'Предварительный просмотр',
            'Off-line режим',
            'Внеплановый визит',
            'Совместный визит',
            'Просмотр магазинов на карте',
            'Выполнение визита',
            'Работа с товарами',
            'Регулярная анкета',
            'Планограммы',
            'Image Recognition',
            'Фото выкладки',
            'Учет времени',
            'Контроль выполнения визита',
            'Отправка результатов',
            'Карта заданий',
            'Карта торговых точек (магазинов)',
            'Лента отчетов для менеджеров',
            'Очередь отправки',
            'Техническая поддержка',
            'Обновление приложения',
            'KPI',
        ],
    }, {
        id: 2,
        title: 'На веб-платформе',
        items: [
            'Лента отчетов выполненных визитов',
            'Задания для мерчандайзеров',
            'Конструктор опросников',
            'Назначение опросников',
            'Множественное назначение',
            'График визитов',
            'Регулирование визитов',
            'Регулирование аудитов',
            'Выгрузка графика визитов',
            'Маршруты пользователей',
            'Проверка визитов',
            'Справочники',
            'Торговые точки (магазины)',
            'Команда',
            'Планограммы',
            'Вопросы для проверки визитов',
            'Контрагенты и настройки',
            'KPI по команде',
        ],
    }, {
        id: 3,
        title: 'Импорт данных',
        items: [
            'График визитов для мерчандайзеров',
            'Опросники – активности',
            'Привязка опросников к визитам',
            'Привязка планограмм к визитам',
            'Проверка товарных позиций',
            'Регулирование отображения раздела Image Recognition',
            'Регулирование обязательности опросника в магазинах',
        ],
    }, {
        id: 4,
        title: 'Экспорт данных',
        items: [
            'Выгрузка фотографий',
            'Отчет по товарам',
            'Отчет по визитам',
            'Отчет по опросникам',
            'Отчет по проверкам визитов',
            'Индивидуальные отчеты контрагентов',
        ],
    },
];

const PlatformFunc =  React.memo(() => {
    const [opened, setOpened] = useState({});

    const toggleOpened = (id) => {
        setOpened({ ...opened, [id]: opened[id] ? !opened[id] : true });
    };

    return (
        <section id="platform-func" className={styles.platformFunc}>
            <div className={styles.platformFuncInner}>
                {isDesktop ? (
                    <h2 className={`title ${styles.sectionTitle}`}>
                        <span className="title-split-line">Функционал</span>
                        <span className="title-split-line">платформы</span>
                    </h2>
                ) : (
                    <h2 className={`title ${styles.sectionTitle} fade-in`}>
                        Функционал<br/>платформы
                    </h2>
                )}
                <div className={styles.itemsWrapper}>
                    {DATA_ARRAY.map((el) => {
                        return (
                            <div
                                key={el.title}
                                className={`${isDesktop ? 'fade-in' : ''} ${styles.advantageBlock} ${
                                    opened[el.id] ? styles.advantageBlockOpened : ''
                                }`}
                            >
                                <div className={styles.heading} onClick={() => toggleOpened(el.id)}>
                                    <h3>{el.title}</h3>
                                    {opened[el.id] ? <MinusIcon/> : <PlusIcon/>}
                                </div>
                                {opened[el.id] && (
                                    <div className={styles.lowerBlock}>
                                        {el.items.map((elem) => (
                                            <div key={elem} className={styles.lowerBlockItem}>
                                                <CheckIcon />
                                                <span>{elem}</span>
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>
                        )
                    })}
                </div>
            </div>
        </section>
    )
});

export default PlatformFunc;
