import React  from 'react';
import { isDesktop } from '../../../helpers/constants';
import styles from './mainSection.module.scss';

const MainSection = React.memo(({ onClickAction }) => {
    return (
        <section id="main" className={styles.firstSection}>
            <div className={`${styles.topBlock}`}>
                <div className={styles.posterBlock}>
                    <div className={`${styles.mainTextBlock} ${!isDesktop ? 'fade-in' : ''} title`}>
                        <h1 className="title-split-line">wodo—</h1>
                        <h2 className="title-split-line">автоматизация аудита</h2>
                        <h2 className="title-split-line">и мерчандайзинга в</h2>
                        <h2 className="title-split-line">торговых точках</h2>
                    </div>
                    <p className={`${styles.mainSubtext} fade-in`}>
                        Мобильное приложение и веб-платформа
                    </p>
                    <button className="btn btn-fill-right main-btn-fade-in" onClick={onClickAction}>
                        Получить тестовый доступ
                    </button>
                </div>
            </div>
        </section>
    )
});

export default MainSection;