import React from 'react';
import { ReactComponent as CheckboxMark} from '../../../assets/icons/checkboxMark.svg';
import styles from './checkboxAgree.module.scss';

const CheckboxAgree = React.memo(({ value, type, title, onClick, withLink, linkHref, error }) => {
    return (
        <div className={styles.container}>
            <div className={styles.checkbox} onClick={() => onClick(!value, type)}>
                {value && (<CheckboxMark className={styles.checkboxMark} />)}
            </div>
            <span className={withLink ? styles.smallerText : ''}>
                {`${title} `}
                <a href={linkHref || ''} target="_blank">{withLink || ''}</a>
                {error && (
                    <span className={styles.errorText}><br/>{error}</span>
                )}
            </span>
        </div>
    )
});

export default CheckboxAgree;
