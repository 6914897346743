import React from 'react';
import styles from './input.module.scss';

const Input = ({ value, onChange, inputType, placeholder, error }) => {
    const onChangeLocal = (e) => {
        const val = e.target.value || '';
        onChange && onChange(val)
    };

    return (
        <div className={styles.inputContainer}>
            <div className={`${error ? styles.errorWrapper : styles.inputWrapper}`}>
                <input
                    type={inputType || 'text'}
                    className={styles.input}
                    value={value || ''}
                    onChange={onChangeLocal}
                    placeholder={placeholder || ''}
                    min="0"
                    max="999999999"
                />
                {error && (
                    <span className={styles.errorText}>{error}</span>
                )}
            </div>
        </div>
    )
};

export default Input;
