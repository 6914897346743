import React from 'react';
import { ReactComponent as CheckboxMark} from '../../../assets/icons/checkboxMark.svg';
import styles from './checkbox.module.scss';

const Checkbox = React.memo(({ value, type, title, onClick }) => {
    return (
        <div className={styles.container} onClick={() => onClick(!value, type)}>
            <div className={styles.checkbox}>
                {value && (<CheckboxMark className={styles.checkboxMark} />)}
            </div>
            <span>
                {title || ''}
            </span>
        </div>
    )
});

export default Checkbox;
