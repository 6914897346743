import React, { useState } from 'react';
import { isDesktop } from '../../../helpers/constants';
import { ReactComponent as ArrowIcon } from '../../../assets/icons/leftArrow.svg';
import desktop1 from '../../../assets/support/desktop-1.png';
import desktop2 from '../../../assets/support/desktop-2.png';
import desktop3 from '../../../assets/support/desktop-3.png';
import desktop4 from '../../../assets/support/desktop-4.png';
import mobile1 from '../../../assets/support/mobile-1.png';
import mobile2 from '../../../assets/support/mobile-2.png';
import mobile3 from '../../../assets/support/mobile-3.png';
import mobile4 from '../../../assets/support/mobile-4.png';
import styles from './supportWork.module.scss';

const Arrow = React.memo(({ onClick, rotate }) => {
    return (
        <div
            onClick={onClick}
            className={`${styles.iconWrapper} ${rotate ? styles.rotate180 : styles.rotate0}`}
        >
            <ArrowIcon />
        </div>
    )
});

const MOBILE_PAGES = [
    {
        page: 1,
        text: 'Откройте меню',
        img: mobile1,
        alt: 'page-1-mobile',
    },
    {
        page: 2,
        text: 'Выберите пункт «Написать в поддержку»',
        img: mobile2,
        alt: 'page-2-mobile',
    },
    {
        page: 3,
        text: 'В открывшемся окне выберите тип проблемы',
        img: mobile3,
        alt: 'page-3-mobile',
    },
    {
        page: 4,
        text: 'Опишите, что пошло не так, прикрепите фото или скриншот, если они помогут нам лучше разобраться' +
            ' в вашей ситуации, и нажмите «Отправить».',
        img: mobile4,
        alt: 'page-4-mobile',
    },
];

const DESKTOP_PAGES = [
    {
        page: 1,
        text: 'Нажмите на значок вашего профиля.',
        img: desktop1,
        alt: 'page-1-desktop',
    },
    {
        page: 2,
        text: 'В появившемся меню выберите пункт «Сообщить о проблеме».',
        img: desktop2,
        alt: 'page-2-desktop',
    },
    {
        page: 3,
        text: 'Выберите тип проблемы. Если не нашли в списке подходящий, нажмите «Другое».',
        img: desktop3,
        alt: 'page-3-desktop',
    },
    {
        page: 4,
        text: 'Опишите, что пошло не так, прикрепите фото или скриншот, если они помогут нам лучше разобраться в ' +
            'вашей ситуации, и нажмите «Отправить».',
        img: desktop4,
        alt: 'page-4-desktop',
    },
];

const SupportWork = React.memo(() => {
    const [mode, setMode] = useState('mobile');
    const [page, setPage] = useState(1);
    const mobileSlider = mode === 'mobile';

    const selectPage = (x) => {
        setPage(x)
    };

    return (
        <section id="support-work" className={styles.supportWork}>
            <div className={styles.supportWorkInner}>
                <div className={styles.leftBlock}>
                    <h2 className={`title ${styles.sectionTitle} ${!isDesktop ? 'fade-in' : ''}`}>
                        <span className="title-split-line">Как работает</span>
                        <span className="title-split-line">поддержка</span>
                    </h2>
                    <p className={`${isDesktop ? 'fade-in' : ''}`}>
                        Мы поможем с любыми техническими вопросами, например: с установкой, настройкой
                        и обновлением программного обеспечения.
                        <br />
                        <br />
                        А если возникнет ошибка или вопрос по функционалу, вы всегда можете написать
                        в поддержку через приложение и веб-платформу.
                        <br />
                        <br />
                        Или можете написать на почту службы поддержки:
                        <a href="mailto:support@mywodo.ru" className={styles.contactEmail}>
                            {` support@mywodo.ru`}
                        </a>
                    </p>
                </div>
                <div className={styles.rightBlock}>
                    <div className={styles.rightBlockInner}>
                        <div className={styles.slider}>
                            <span
                                onClick={() => setMode('mobile')}
                                className={`${styles.sliderItem} ${mobileSlider ? styles.sliderActive : ''}`}
                            >
                                В мобильной версии
                            </span>
                            <span
                                onClick={() => setMode('desktop')}
                                className={`${styles.sliderItem} ${!mobileSlider ? styles.sliderActive : ''}`}
                            >
                                На веб-платформе
                            </span>
                        </div>
                        <div className={mobileSlider ? styles.mainBlockRow : styles.mainBlockColumn}>
                            {(mobileSlider ? MOBILE_PAGES : DESKTOP_PAGES).map((el) => (
                                <div
                                    key={`sliding-el-${el.page}`}
                                    className={`${styles.mainBlockItem} ${el.page !== page ? styles.hide : ''}`}
                                >
                                    <div className={styles.mainBlockImgWrapper}>
                                        <img
                                            width={mobileSlider ? 240 : 647}
                                            height={mobileSlider ? 454 : 445}
                                            src={el.img}
                                            alt={el.alt}
                                        />
                                    </div>
                                    <span className={styles.mainHint}>
                                        {el.text}
                                    </span>
                                </div>
                            ))}
                        </div>
                        <div className={styles.pagination}>
                            <Arrow
                                onClick={() => selectPage(page === 1 ? MOBILE_PAGES.length : page - 1)}
                            />
                            {MOBILE_PAGES.map((el) => (
                                <div
                                    key={`pagination-el-${el.page}`}
                                    onClick={() => selectPage(el.page)}
                                    className={`${styles.paginationItem} ${page === el.page ? styles.itemActive : ''}`}
                                >
                                    {el.page || 1}
                                </div>
                            ))}
                            <Arrow
                                onClick={() => selectPage(page === MOBILE_PAGES.length ? 1 : page + 1)}
                                rotate
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className={`${styles.ellipse} circle-path`} />
        </section>
    )
});

export default SupportWork;
