import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { gsap } from 'gsap';
import { ReactComponent as ArrowIcon } from '../../assets/icons/arrowBlackDown.svg';
import logo from '../../assets/wodoLogo.svg';
import styles from './header.module.scss';

const MOBILE_MENU = [
    { title: 'Wodo', link: '#main' },
    { title: 'Плюсы', link: '#pluses' },
    { title: 'Как начать', link: '#how-to-start' },
    { title: 'Возможности', link: '#wodo-for' },
    { title: 'Функционал', link: '#platform-func' },
    { title: 'Команда', link: '#team' },
    { title: 'Мы в цифрах', link: '#wodo-numbers' },
    { title: 'Клиенты', link: '#clients-trust' },
    { title: 'Отзывы', link: '#client-about-us' },
    { title: 'Тарифы', link: '#pricing' },
    { title: 'Техподдержка', link: '#support-work' },
    { title: 'Документация', link: '#documents' },
    { title: 'Скачать приложение', link: '#footer' },
    { title: 'Контакты', link: '#footer' },
];

const Header = React.memo(({ onOpenDropdown }) => {
    const [opened, setOpened] = useState('');
    const [prevDirection, setPrevDirection] = useState(0);
    const [blurred, setBlurred] = useState(false);
    const ref = useRef(null);

    const doc = document.documentElement;
    let prevScroll = window.scrollY || doc.scrollTop;
    let direction = 0;

    useLayoutEffect(() => {
        window.addEventListener('scroll', checkScroll);
    });

    useEffect(() => {
        const checkMenu = (e) => {
            if (opened && ref.current && !ref.current.contains(e.target)) {
                onOpenDropdown();
                toggleMenu();
            }
        };
        document.addEventListener('mousedown', checkMenu);
        return () => document.removeEventListener('mousedown', checkMenu);
    }, [opened]);

    const checkScroll = () => {
        const curScroll = window.scrollY || doc.scrollTop;

        if (curScroll > prevScroll) {
            direction = 2;
        } else if (curScroll < prevScroll) {
            direction = 1;
        }

        if (direction !== prevDirection) {
            toggleHeader(direction, curScroll);
        }

        prevScroll = curScroll;
    };


    const toggleHeader = (direction, curScroll) => {
        if (direction === 2 && curScroll > 92) {
            setPrevDirection(2);
            if (!blurred && curScroll > 100) {
                setBlurred(true);
            }
        } else if (direction === 1) {
            setPrevDirection(1);
            if (blurred && curScroll < 101) {
                setBlurred(false);
            }
        }
    };

    const goTo = (id) => {
        if (opened) {
            onOpenDropdown();
            toggleMenu();
        }
        gsap.to(window, { duration: 1, scrollTo: id });
    };

    const toggleMenu = (id) => {
        if ((!opened && id) || (opened !== '' && id === opened)) {
            onOpenDropdown();
        }
        if (opened === id) {
            return setOpened('');
        }
        setOpened(id);
    };

    return (
        <header
            className={`${styles.header} ${
                !opened && prevDirection === 2 ? styles.hideHeader : ''
            } ${blurred ? styles.blurHeader : ''}`}
            ref={ref}
        >
            <div className={styles.headerInner}>
                <img
                    onClick={() => goTo('#main')}
                    src={logo}
                    className={styles.headerLogo} alt="logo"
                />
                <nav className={styles.navMenu}>
                    <ul>
                        <li className={styles.navItem} onClick={() => toggleMenu('product')}>
                            О продукте
                            <ArrowIcon
                                className={`${styles.arrowDown} ${opened === 'product' ? styles.rotate180 : ''}`}
                                alt="arrow"
                            />
                        </li>
                        <li className={styles.navItem} onClick={() => toggleMenu('us')}>
                            О нас
                            <ArrowIcon
                                className={`${styles.arrowDown} ${opened === 'us' ? styles.rotate180 : ''}`}
                                alt="arrow"
                            />
                        </li>
                        <li className={styles.navItem} onClick={() => goTo('#clients-trust')}>
                            Клиенты
                        </li>
                        <li className={styles.navItem} onClick={() => goTo('#client-about-us')}>
                            Отзывы
                        </li>
                        <li className={styles.navItem} onClick={() => goTo('#pricing')}>
                            Тарифы
                        </li>
                        <li className={styles.navItem} onClick={() => goTo('#support-work')}>
                            Техподдержка
                        </li>
                        <li className={styles.navItem} onClick={() => goTo('#footer')}>
                            Контакты
                        </li>
                    </ul>
                    {opened === 'product' && (
                        <ul className={styles.dropdownList}>
                            <li onClick={() => goTo('#main')}>Wodo</li>
                            <li onClick={() => goTo('#pluses')}>Плюсы</li>
                            <li onClick={() => goTo('#how-to-start')}>Как начать</li>
                            <li onClick={() => goTo('#wodo-for')}>Возможности</li>
                            <li onClick={() => goTo('#platform-func')}>Функционал</li>
                            <li onClick={() => goTo('#documents')}>Документация</li>
                            <li onClick={() => goTo('#footer')}>Скачать приложение</li>
                        </ul>
                    )}
                    {opened === 'us' && (
                        <ul className={`${styles.dropdownList} ${styles.dropdownUs}`}>
                            <li onClick={() => goTo('#team')}>Команда</li>
                            <li onClick={() => goTo('#wodo-numbers')}>Мы в цифрах</li>
                        </ul>
                    )}
                </nav>
                <div className={styles.mobileMenu}>
                    <div
                        id="nav-icon"
                        onClick={() => toggleMenu('mobile')}
                        className={`${styles.mobileMenuIcon} ${
                            opened === 'mobile' ? styles.mobileIconOpened : ''
                        }`}
                    >
                        <span /><span /><span /><span />
                    </div>
                    {opened === 'mobile' && (
                        <div className={styles.mobileMenuWrapper}>
                            <div className={styles.mobileMenuContainer}>
                                <nav className={styles.navMenuMobile}>
                                    <ul>
                                        {MOBILE_MENU.map((el, idx) => (
                                            <li
                                                key={`${el.link}-${idx}`}
                                                onClick={() => goTo(el.link)}
                                            >
                                                {el.title}
                                            </li>
                                        ))}
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </header>
    )
});

export default Header;
