import React from 'react';
import { isDesktop } from '../../../helpers/constants';
import { ReactComponent as DownloadIcon } from '../../../assets/icons/download.svg';
import { ReactComponent as WodoLogo } from '../../../assets/wodoLogo.svg';
import styles from './documents.module.scss';

const Documents = React.memo(({ onClickAction }) => {
    return (
        <section id="documents" className={styles.documentsMain}>
            <div className={styles.documentsInner}>
                <h2 className={`title ${styles.sectionTitle} ${isDesktop ? '' : 'fade-in'}`}>
                    <span className="title-split-line">Документация</span>
                </h2>
                <div className={styles.buttonsBlock}>
                    <div
                        className={`${styles.item} ${isDesktop ? '' : 'fade-in'}`}
                        onClick={() => window.open('files/doc1.pdf')}
                    >
                        <p>
                            Инструкция по установке экземпляра программного обеспечения WODO
                        </p>
                        <div className={styles.downloadRow}>
                            <span>Скачать файл</span>
                            <DownloadIcon />
                        </div>
                    </div>
                    <div
                        className={`${styles.item} ${isDesktop ? '' : 'fade-in'}`}
                        onClick={() => window.open('files/doc2.pdf')}
                    >
                        <p>
                            Документация, содержащая информацию, необходимую для эксплуатации
                            экземпляра программного обеспечения WODO
                        </p>
                        <div className={styles.downloadRow}>
                            <span>Скачать файл</span>
                            <DownloadIcon />
                        </div>
                    </div>
                    <div
                        className={`${styles.item} ${isDesktop ? '' : 'fade-in'}`}
                        onClick={() => window.open('files/doc3.pdf')}
                    >
                        <p>
                            Документация, содержащая описание функциональных характеристик программного
                            обеспечения WODO
                        </p>
                        <div className={styles.downloadRow}>
                            <span>Скачать файл</span>
                            <DownloadIcon />
                        </div>
                    </div>
                </div>
                <div className={styles.logoBlock}>
                    <WodoLogo className={styles.wodoLogo} />
                    <button className="btn btn-fill-right" onClick={onClickAction}>
                        Получить тестовый доступ
                    </button>
                    <div className={styles.ellipseOrange} />
                </div>
            </div>
        </section>
    )
});

export default Documents;