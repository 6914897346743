import React from 'react';
import ourProd from '../../../assets/ourProd.svg';
import webScreen from '../../../assets/webScreen.jpg';
import mobileScreen from '../../../assets/mobileScreen.png';
import { fromPxToInit } from '../../../helpers/constants';
import styles from './wodoIs.module.scss';

const WodoIs = React.memo(() => {
    const isMobile = !window.matchMedia(fromPxToInit).matches;
    return (
        <section id="wodo-is" className={styles.secondSection}>
            <h2 className={`title ${styles.sectionTitle} ${isMobile ? 'fade-in' : ''}`}>
                <span className="title-split-line">wodo — это ответ</span>
                <span className="title-split-line">сотрудникам на вопрос</span>
                <span className="title-split-line">what to do</span>
            </h2>
            <div className={styles.imagesBlockContainer}>
                <div className={styles.webScreenImageBlock}>
                    <img className={styles.webScreenImg} src={webScreen} alt="web-screen" />
                    <div className={styles.mobileScreenImageBlock}>
                        <img className={styles.mobileScreenImg} src={mobileScreen} alt="mobile-screen" />
                    </div>
                </div>
                <div className={styles.rotatingImgWrapper}>
                    <img className="rotating" src={ourProd} alt="our-production" />
                </div>

                <div className={styles.underImgTextBlock}>
                    <p className="fade-in">
                        С помощью приложения WODO полевые сотрудники собирают статистику и проводят проверки
                        в торговых точках по вашим чек-листам, отправляют отчёты.
                    </p>
                    <p className="fade-in">
                        На веб-платформе менеджеры работают со статистикой,
                        отчётами и управляют командой, например, контролируют нагрузку на сотрудников,
                        создают задачи и оценивают эффективность работы.
                    </p>
                </div>
            </div>
            <div className={`${styles.ellipse} circle-path`} />
        </section>
    )
});

export default WodoIs;