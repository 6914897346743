import React, { useEffect, useRef, useState } from 'react';
import Input from './Input';
import Textarea from './Textarea';
import Checkbox from './Checkbox';
import CheckboxAgree from './CheckboxAgree';
import { ReactComponent as CrossIcon } from '../../assets/icons/cross.svg';
import { ReactComponent as WodoLogo } from '../../assets/wodoLogo.svg';
import styles from './popup.module.scss';

const Popup = ({ togglePopup }) => {
    const preFill = localStorage.getItem('savedData') || '';
    const initObj = preFill ? JSON.parse(preFill) : {
        integration_accounting: false,
        availability_control: false,
        integration_recognition: false,
        comment: '',
    };
    const [obj, setObj] = useState(initObj);
    const [error, setError] = useState({});
    const [globalError, setGlobalError] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [sent, setSent] = useState(false);
    const ref = useRef(null);
    const disableBtn = disabled || error.name || error.phone || error.email || error.agreed;

    useEffect(() => {
        return () => localStorage.setItem('savedData', JSON.stringify(obj));
    });


    useEffect(() => {
        const checkPopup = (e) => {
            if (ref.current && !ref.current.contains(e.target)) {
                closePopup()
            }
        };
        document.addEventListener('mousedown', checkPopup);
        return () => document.removeEventListener('mousedown', checkPopup);
    }, []);

    const onChangeInput = (val, type) => {
        let value = val || '';
        if (type === 'phone') {
            if (/^[()0-9.+\- ]+$/.test(value) || val === '') {
                setError({ ...error, [type]: false });
                return setObj({ ...obj, [type]: value });
            }
            return;
        }
        if (type === 'outlets_number' || type === 'employees_number') {
            value = parseInt(value);
            if (/^([0-9]){0,9}$/.test(value) || val === '') {
                return setObj({ ...obj, [type]: value });
            }
            return;
        }
        setError({ ...error, [type]: false });
        setObj({ ...obj, [type]: value });
    };

    const onChangeCheckbox = (val, type) => {
        if (type === 'agreed') {
            setError({ ...error, agreed: false });
        }
        setObj({ ...obj, [type]: val });
    };

    const checkIfAllValid = () => {
        let passed = true;
        const errorObj = { ...error };
        const { name, email, phone, agreed } = obj;
        const nameStr = (name || '').trim();

        if (!nameStr) {
            passed = false;
            errorObj.name = 'Обязательное поле';
        }

        if (!phone || (phone && !(/^(\+)?([ 0-9()-]){10,18}$/.test(phone)))) {
            passed = false;
            errorObj.phone = !phone ? 'Обязательное поле' : 'Не корректное значение';
        }

        if (!email || (email && !(/^(.+)@(.+)$/.test(email)))) {
            passed = false;
            errorObj.email = !email ? 'Обязательное поле' : 'Не корректное значение';
        }

        if (!agreed) {
            passed = false;
            errorObj.agreed = 'Обязательное поле';
        }

        setError(errorObj);
        return passed;
    };

    const onClickSubmit = () => {
        const valid = checkIfAllValid();
        if (!valid) return;

        setDisabled(true);
        setGlobalError(false);

        const newObj = { ...obj };
        delete newObj.agreed;

        fetch('/api/landingmail', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(newObj)
        })
            .then((res) => {
                if ([200, 201, 204].includes(res?.status)) {
                    setSent(true);
                } else {
                    setGlobalError(true);
                }
                setDisabled(false);
            })
            .catch((e) => {
                setGlobalError(true);
                setDisabled(false);
            })
    };

    const onClickReSubmit = () => {
        setSent(false);
    };

    const closePopup = () => {
        localStorage.setItem('savedData', JSON.stringify(obj));
        togglePopup();
    };

    return (
        <div className={styles.popupAll}>
            <div className={styles.popupWrapper}>
                <div className={styles.popupContainer}>
                    <div className={styles.innerBlock} ref={ref}>
                        <div className={styles.ellipse} />
                        <CrossIcon className={styles.closeIcon} onClick={closePopup} />
                        <div className={styles.popupHeader}>
                            {!sent && (
                                <>
                                    <h3>
                                        Отправьте заявку, чтобы получить доступ и начать сотруднечиство
                                    </h3>
                                    <p>
                                        Чем больше информации вы укажете, тем лучше мы сможем подготовиться
                                        для презентации вам продукта.
                                    </p>
                                </>
                            )}
                            <WodoLogo className={styles.wodoLogo} />
                        </div>
                        {!sent && (
                            <div className={styles.popupBody}>
                                <div className={styles.popupBodyRow}>
                                    <p className={styles.rowTitle}>
                                        Обязательные поля для заполнения<span>*</span>
                                    </p>
                                    <Input
                                        value={obj.name}
                                        placeholder="Имя*"
                                        onChange={(v) => onChangeInput(v, 'name')}
                                        error={error.name}
                                    />
                                    <Input
                                        value={obj.phone}
                                        placeholder="Телефон*"
                                        onChange={(v) => onChangeInput(v, 'phone')}
                                        error={error.phone}
                                    />
                                    <Input
                                        value={obj.email}
                                        placeholder="E-mail*"
                                        onChange={(v) => onChangeInput(v, 'email')}
                                        inputType="email"
                                        error={error.email}
                                    />
                                </div>
                                <div className={styles.popupBodyRow}>
                                    <p className={styles.rowTitle}>
                                        Было бы хорошо уточнить
                                    </p>
                                    <Input
                                        value={obj.company_name}
                                        placeholder="Название компании"
                                        onChange={(v) => onChangeInput(v, 'company_name')}
                                    />
                                    <Input
                                        value={obj.city}
                                        placeholder="Город"
                                        onChange={(v) => onChangeInput(v, 'city')}
                                    />
                                    <Input
                                        value={obj.employees_number}
                                        placeholder="Количество сотрудников"
                                        onChange={(v) => onChangeInput(v, 'employees_number')}
                                        inputType="number"
                                    />
                                    <Input
                                        value={obj.outlets_number}
                                        placeholder="Количество торговых точек"
                                        onChange={(v) => onChangeInput(v, 'outlets_number')}
                                        inputType="number"
                                    />
                                </div>
                                <div className={styles.popupBodyRow}>
                                    <p className={styles.rowTitle}>
                                        Вам может потребоваться
                                    </p>
                                    <Checkbox
                                        type="integration_accounting"
                                        value={obj.integration_accounting}
                                        onClick={onChangeCheckbox}
                                        title="Интеграция с учетными системами"
                                    />
                                    <Checkbox
                                        type="availability_control"
                                        value={obj.availability_control}
                                        onClick={onChangeCheckbox}
                                        title="Контроль наличия товара"
                                    />
                                    <Checkbox
                                        type="integration_recognition"
                                        value={obj.integration_recognition}
                                        onClick={onChangeCheckbox}
                                        title="Интеграция с системой распознавания"
                                    />
                                    <span className={styles.checkboxTip}>
                                        Вы можете выбрать нужное вам из списка
                                    </span>
                                </div>
                            </div>
                        )}

                        {!sent && (
                            <div className={styles.popupFooter}>
                                <div className={styles.leftBlock}>
                                    <span>Ваши комментарии</span>
                                    <Textarea
                                        value={obj.comment}
                                        onChange={(v) => onChangeCheckbox(v, 'comment')}
                                        placeholder="Описание компании, задач,
                                         удобный способ и время связи — всё, что кажется важным"
                                    />
                                </div>
                                <div className={styles.rightBlock}>
                                    <CheckboxAgree
                                        type="agreed"
                                        value={obj.agreed}
                                        onClick={onChangeCheckbox}
                                        title="Я согласен"
                                        withLink="с условиями обработки персональных данных*"
                                        linkHref="/privacy"
                                        error={error.agreed}
                                    />
                                    <div className={styles.btnWrapper}>
                                        <button
                                            className={`btn btn-fill-right ${disableBtn ? 'btn-disabled' : ''}`}
                                            onClick={onClickSubmit}
                                            disabled={disableBtn}
                                        >
                                            Отправить заявку
                                        </button>
                                        {globalError && (
                                            <span className={styles.globalErrorText}>
                                                Что-то пошло не так. Попробуйте позже.
                                            </span>
                                        )}
                                        <div className={styles.ellipseOrangeSmall} />
                                    </div>
                                </div>
                            </div>
                        )}
                        {sent && (
                            <div className={styles.sentBlock}>
                                <span className={styles.sentBlockTitle}>
                                    Спасибо за заявку!
                                    <br />
                                    Мы скоро перезвоним
                                </span>
                                <span className={styles.sentBlockText}>
                                    Мы приняли заявку и свяжемся в этот же или в
                                    <br />
                                    ближайший рабочий день, если вы отправили
                                    <br />
                                    заявку вечером или в выходные.
                                </span>
                                <div className={styles.sentBtnWrapper}>
                                    <button
                                        className="btn btn-fill-right"
                                        onClick={onClickReSubmit}
                                    >
                                        Отправить заявку ещё раз
                                    </button>
                                    <div className={styles.ellipseOrange} />
                                </div>
                                <span className={styles.sentBtnHint}>
                                    Если вы забыли что-то добавить или указали не те
                                    <br />
                                    контакты, можете отправить заявку ещё раз
                                </span>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
};

export default Popup;
