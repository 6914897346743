import React, { useCallback, useContext, useEffect, useState } from 'react';
import { gsap } from 'gsap';
import { SplitText } from 'gsap/SplitText';
import { ScrollSmoother } from 'gsap/ScrollSmoother';
import { AppContext } from '../../helpers/context';
import { isDesktop } from '../../helpers/constants';
import MainSection from './MainSection';
import ParallaxImage from './ParallaxImage';
import TrustUs from './TrustUs';
import WodoIs from './WodoIs';
import Documents from './Documents';
import Pluses from './Pluses';
import HowToStart from './HowToStart';
import WodoFor from './WodoFor';
import PlatformFunc from './PlatformFunc';
import Requirements from './Requirements';
import AboutUs from './AboutUs';
import WodoNumbers from './WodoNumbers';
import ClientsAboutUs from './ClientsAboutUs';
import Pricing from './Pricing';
import SupportWork from './SupportWork';
import styles from './main.module.scss';

const LAG_TIME = 0.015;

const Main = React.memo(({ navOpened, onClickAction }) => {
    const [smoother, setSmoother] = useState(null);
    const loaded = useContext(AppContext);

    const initAll = useCallback(() => {
        gsap.set('.main-btn-fade-in', { autoAlpha: 1 });
        gsap.from('.main-btn-fade-in', {
            delay: 0.3,
            duration: 0.6,
            yPercent: 100,
            opacity: 0,
        });

        gsap.utils.toArray('.title').forEach((title) => {
            if (title.querySelector('.title-split-char')) return;

            new SplitText(title, {
                type: 'chars',
                tag: 'span',
                charsClass: 'title-split-char',
            });

            const lines = title.querySelectorAll('.title-split-line');

            lines.forEach((line) => {
                line.querySelectorAll('.title-split-char').forEach((char, i) => {
                    smoother.effects(char, {
                        speed: 1,
                        lag: ((i > 0 ? i : 0.5) + 1) * LAG_TIME - LAG_TIME,
                    });
                });
            });

            const secondSplitText = new SplitText(title, {
                type: 'chars',
                tag: 'span',
                charsClass: 'title-split-deep-char',
            });

            gsap.from(secondSplitText.chars, {
                scrollTrigger: {
                    trigger: title,
                    start: 'top bottom+=20',
                    onLeaveBack: () => {
                        title.classList.remove('title-showed');
                    },
                },
                duration: 0.6,
                delay: 0.2,
                ease: 'circ.out',
                y: secondSplitText?.chars[0]?.offsetHeight ?? 70,
                stagger: 0.01,
                onComplete: () => {
                    title.classList.add('title-showed');
                },
            });
        });
    }, [smoother]);

    const startFadeInAnim = () => {
        gsap.utils.toArray('.fade-in').forEach((element) => {
            gsap.from(element, {
                scrollTrigger: {
                    trigger: element,
                    start: 'top-=30 bottom',
                },
                duration: 0.6,
                delay: isDesktop ? 0.25 : 0,
                opacity: 0,
                y: element.offsetHeight > 250 ? 120 : 50,
                ease: 'none',
            });
        });
    };

    useEffect(() => {
        if (loaded && isDesktop) {
            setSmoother(ScrollSmoother.create({
                    wrapper: '#wrapper',
                    content: '#content',
                    smooth: 1,
                    effects: true,
                    // normalizeScroll: true,
                    ignoreMobileResize: false,
                })
            );
        }
    }, [loaded, isDesktop]);

    useEffect(() => {
        if (loaded) {
            startFadeInAnim();
        }
    }, [loaded]);

    useEffect(() => {
        if (loaded && smoother) {
            initAll();
        }
    }, [loaded, smoother, initAll]);

    useEffect(() => {
        if (navOpened) {
            smoother?.paused && smoother.paused(true);
        } else {
            smoother?.paused && smoother.paused(false);
        }
    }, [navOpened, smoother]);

    return (
        <main className={`${styles.main} ${navOpened ? styles.mainBlurred : ''}`}>
            <MainSection onClickAction={onClickAction} />
            <ParallaxImage />
            <TrustUs />
            <WodoIs />
            <Pluses />
            <HowToStart onClickAction={onClickAction} />
            <WodoFor />
            <PlatformFunc />
            <Requirements />
            <AboutUs />
            <WodoNumbers />
            <TrustUs full />
            <ClientsAboutUs />
            <Pricing onClickAction={onClickAction} />
            <SupportWork />
            <Documents onClickAction={onClickAction} />
        </main>
    )
});

export default Main;
