import React from 'react';
import styles from './parallaxImage.module.scss';

const ParallaxImage = React.memo(() => {
    return (
        <div className={styles.parallaxContainer}>
            <div className={styles.parallaxWrapper}>
                <img data-speed="auto" src="/img/merch.jpg" alt="main-poster" />
            </div>
            <div className={styles.ellipse} />
            <div className={styles.ellipseOrange} />
        </div>
    )
});

export default ParallaxImage;
