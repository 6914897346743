import React  from 'react';
import { ReactComponent as RaketaLogo } from '../../assets/raketaLogo.svg';
import appStoreIcon from '../../assets/appStore.png';
import googlePlayIcon from '../../assets/googlePlay.png';
import styles from './footerMobile.module.scss';

const FooterMobile = React.memo(({ navOpened }) => {
    const openLink = (url) => {
        window.open(url, '_blank').focus();
    };

    return (
        <footer id="footer" className={`${styles.footerMobile} ${navOpened ? styles.footerBlurred : ''}`}>
            <section className={styles.footerMobileSection}>
                <div className={styles.infoItem}>
                    <span className={styles.infoTitle}>Контакты</span>
                    <hr className={`hr-anim-footer ${styles.footerHr}`}/>
                    <div className={styles.contactBlocks}>
                        <span>Адрес</span>
                        <span className={styles.contactBlocksText}>
                                ул. Кубанская набережная 39, офис Адмирал, г. Краснодар
                            </span>
                    </div>
                    <div className={styles.contactBlocks}>
                        <a href="mailto:support@mywodo.ru" className={styles.contactBlocksText}>
                            support@mywodo.ru
                        </a>
                    </div>
                </div>
                <div className={styles.infoItem}>
                    <span className={styles.infoTitle}>App WODO</span>
                    <hr className={`hr-anim-footer ${styles.footerHr}`}/>
                    <span className={styles.infoText}>Скачать мобильное приложение</span>
                    <div className={styles.storesWrapper}>
                        <img
                            src={googlePlayIcon}
                            className={styles.googlePlayIcon}
                            onClick={() =>
                                openLink('https://play.google.com/store/apps/details?id=com.wodomobile')
                            }
                            alt="googlePlayIcon"
                        />
                        <img
                            src={appStoreIcon}
                            className={styles.appStoreIcon}
                            onClick={() =>
                                openLink('https://apps.apple.com/ru/app/wodo/id1464919898')
                            }
                            alt="appStoreIcon"
                        />
                    </div>
                </div>
                <div className={styles.infoItem}>
                    <span className={styles.infoTitle}>Разработчик</span>
                    <hr className={`hr-anim-footer ${styles.footerHr}`}/>
                    <span className={styles.infoText}>Компания</span>
                    <RaketaLogo
                        className={styles.raketaLogo}
                        onClick={() =>
                            openLink('https://www.raketa.im/')
                        }
                    />
                </div>
            </section>
        </footer>
    )
});

export default FooterMobile;