import React, { useContext, useEffect } from 'react';
import { gsap } from 'gsap';
import { AppContext } from '../../../helpers/context';
import styles from './wodoNumbers.module.scss';

const WodoNumbers = React.memo(() => {
    const loaded = useContext(AppContext);

    const numberWithSpace = (x) => {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    };

    useEffect(() => {
        if (loaded) {
            addCounter();
        }
    }, [loaded]);

    const addCounter = () => {
        gsap.utils.toArray('.wodo-numbers-data').forEach((el) => {
            gsap.from(el, {
                scrollTrigger: el,
                textContent: 0,
                duration: 2,
                ease: 'power1.in',
                modifiers: {
                    innerText: function (innerText) {
                        return numberWithSpace(Math.ceil(innerText));
                    }
                },
                snap: { textContent: 1 },
            })
        });
    };

    return (
        <section id="wodo-numbers" className={styles.numbersMain}>
            <div className={styles.numbersMainInner}>
                <div className={styles.block}>
                    <span className={styles.number}>
                        <span className="wodo-numbers-data">10000</span>
                        <span>+</span>
                    </span>
                    <span className={styles.text}>
                        Пользователей в системе
                    </span>
                </div>
                <div className={styles.block}>
                    <span className={styles.number}>
                        <span className="wodo-numbers-data">400000</span>
                        <span>+</span>
                    </span>
                    <span className={styles.text}>
                        Отчётов приходит из торговых точек каждый месяц
                    </span>
                </div>
                <div className={styles.block}>
                    <span className={styles.number}>
                        <span className="wodo-numbers-data">50</span>
                        <span>Тб+</span>
                    </span>
                    <span className={styles.text}>
                        Отчётных данных в нашем хранилище на российских серверах
                        <br />
                        <br />
                        *Все данные защищены по152-ФЗ
                    </span>
                </div>
            </div>
            <div className={`${styles.ellipse} circle-path`} />
        </section>
    )
});

export default WodoNumbers;