import React, { useContext, useEffect } from 'react';
import { gsap } from 'gsap';
import { AppContext } from '../../../helpers/context';
import { isDesktop, isTablet } from '../../../helpers/constants';
import styles from './howToStart.module.scss';

const HowToStart = React.memo(({ onClickAction }) => {
    const loaded = useContext(AppContext);

    useEffect(() => {
        if (loaded && isTablet) {
            show();
        }
    }, [loaded]);

    const show = () => {
        gsap.utils.toArray('.hr-anim-how-start').forEach((el) => {
            gsap.from(
                el,
                {
                    scrollTrigger: {
                        trigger: el,
                        start: 'bottom bottom',
                    },
                    width: '0%',
                    opacity: 0,
                    delay: 0.3,
                    duration: 1.5,
                    ease: 'power1.out',
                });
        });
    };

    return (
        <section id="how-to-start" className={styles.howToStart}>
            <div className={styles.howToStartInner}>
                <h2 className={`title ${styles.sectionTitle} ${!isDesktop ? 'fade-in' : ''}`}>
                    <span className="title-split-line">Как начать</span>
                    <span className="title-split-line">работу</span>
                    <span className="title-split-line">c wodo</span>
                </h2>
                <div className={styles.stepsBlock}>
                    <hr className={`hr-anim-how-start ${styles.howToStartHr}`} />
                    <div className={`fade-in ${styles.itemWrapper}`}>
                        <h2>01</h2>
                        <h3>Отправьте заявку</h3>
                        <p>
                            Наш менеджер свяжется с вами в течение рабочего дня и презентует веб-платформу и
                            мобильное приложение WODO любым удобным вам способом,
                            например, на личной встрече или посредством видеосвязи.
                        </p>
                    </div>
                    <hr className={`hr-anim-how-start ${styles.howToStartHr}`} />
                    <div className={`fade-in ${styles.itemWrapper}`}>
                        <h2>02</h2>
                        <h3>Попробуйте в тестовом режиме</h3>
                        <p>
                            Чтобы вы могли познакомиться с новым инструментом, мы откроем тестовый доступ, поможем
                            во всём разобраться: проанализируем ваши бизнес-проссы, предложим доработки,
                            добавим все нужные справочники, настроим конфигурацию.
                        </p>
                    </div>
                    <hr className={`hr-anim-how-start ${styles.howToStartHr}`} />
                    <div className={`fade-in ${styles.itemWrapper}`}>
                        <h2>03</h2>
                        <h3>Всё готово к работе</h3>
                        <p>
                            После пробного периода и обучения мы обсудим условия сотрудничества и заключим договор.
                            А после заключения вы всегда можете обратиться за помощью к личному менеджеру и вызвать
                            наших специалистов — мы не оставим вас один на один с продуктом.
                        </p>
                    </div>
                    <hr className={`hr-anim-how-start ${styles.howToStartHr}`} />
                    <div className={styles.btnWrapper}>
                        <button className="btn btn-fill-right" onClick={onClickAction}>
                            Отправить заявку
                        </button>
                    </div>
                </div>
            </div>
            <div className={`${styles.ellipseOrange} circle-path`} />
        </section>
    )
});

export default HowToStart;
