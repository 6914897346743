import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import { ReactComponent as ArrowIcon } from '../../../assets/icons/leftArrow.svg';
import { ReactComponent as MagnitLogo } from '../../../assets/brands/Magnit.svg';
import { ReactComponent as AncorLogo } from '../../../assets/brands/Ancor.svg';
import { ReactComponent as OpenLogo } from '../../../assets/brands/OpenAgency.svg';
import styles from './clientsAboutUs.module.scss';

const Arrow = React.memo(({ onClickHandler, rotate }) => {
    return (
        <div
            onClick={onClickHandler}
            className={`${styles.iconWrapper} ${rotate ? styles.rotate180 : styles.rotate0}`}
        >
            <ArrowIcon />
        </div>
    )
});

const CarouselItemMagnit = React.memo(() => {
    return (
        <div className={styles.carouselItem}>
            <div className={styles.itemLeftBlock}>
                <MagnitLogo classname={styles.magnitLogo} />
                <span className={styles.itemName}>Ольга Спиридонова</span>
                <span className={styles.itemRole}>Менеджер по развитию сети</span>
            </div>
            <p className={styles.itemRightBlock}>
                WODO - простое понятное решение для сотрудников в магазинах. Использовали приложение для
                получения и обработки сигналов по товарам в торговой сети. Отдельное спасибо команде
                разработчиков за обратную связь и быстрое реагирование.
            </p>
        </div>
    )
});

const CarouselItemAncor1 = React.memo(() => {
    return (
        <div className={styles.carouselItem}>
            <div className={styles.itemLeftBlock}>
                <AncorLogo />
                <span className={styles.itemName}>Лаура Салпагарова</span>
                <span className={styles.itemRole}>Супервайзер</span>
            </div>
            <p className={styles.itemRightBlock}>
                В приложении очень удобная и информативная Лента результатов визитов. Визиты загружаются очень быстро,
                это экономит время мерчандайзерам.
            </p>
        </div>
    )
});

const CarouselItemAncor2 = React.memo(() => {
    return (
        <div className={styles.carouselItem}>
            <div className={styles.itemLeftBlock}>
                <AncorLogo />
                <span className={styles.itemName}>Алексей Корнеев</span>
                <span className={styles.itemRole}>Аналитик по автоматизации</span>
            </div>
            <p className={styles.itemRightBlock}>
                WODO новое SFA приложение, которое еще активно развивается и нам нравится, что можно реализовать
                почти любые наши потребности и максимально автоматизировать бизнес-процессы.
            </p>
        </div>
    )
});


const CarouselItemOpen1 = React.memo(() => {
    return (
        <div className={styles.carouselItem}>
            <div className={styles.itemLeftBlock}>
                <OpenLogo classname={styles.openLogo} />
                <span className={styles.itemName}>Open Group</span>
                <span className={styles.itemRole}>Мерчандайзинговое агентство</span>
            </div>
            <p className={styles.itemRightBlock}>
                Очень гибкое решение, подошло нам для автоматизации работ мерчандайзинга у различных  клиентов.
                Очень удобно, что приложение можно дорабатывать под различные бизнес-процессы. Важный плюс приложения,
                что это полностью отечественная разработка и располагается на российских серверах.
            </p>
        </div>
    )
});

const ClientsAboutUs = React.memo(() => {
    const renderIndicators = (onClickHandler, isSelected, index) => {
        return (
            <li
                tabIndex={0}
                key={index}
                onClick={onClickHandler}
                className={isSelected ? styles.indicatorActive : styles.indicatorItem}
            />
        );
    };

    return (
        <section id="client-about-us" className={styles.main} >
            <div className={styles.mainInner}>
                <h2 className={`title ${styles.sectionTitle}`}>
                    <span className="title-split-line">Клиенты</span>
                    <span className="title-split-line">о wodo</span>
                </h2>
                <Carousel
                    emulateTouch
                    swipeable
                    infiniteLoop
                    renderArrowPrev={(onClickHandler) => <Arrow onClickHandler={onClickHandler} />}
                    renderArrowNext={(onClickHandler) => <Arrow onClickHandler={onClickHandler} rotate />}
                    showThumbs={false}
                    showStatus={false}
                    renderIndicator={renderIndicators}
                >
                    <CarouselItemMagnit />
                    <CarouselItemAncor1 />
                    <CarouselItemAncor2 />
                    <CarouselItemOpen1 />
                </Carousel>
            </div>
            <div className={`${styles.ellipseOrange} circle-path`} />
        </section>
    )
});

export default ClientsAboutUs;
