import React, { useContext, useEffect } from 'react';
import { gsap } from 'gsap';
import { Carousel } from 'react-responsive-carousel';
import { AppContext } from '../../../helpers/context';
import { isDesktop, TABLET_PX } from '../../../helpers/constants';
import { useWindowSize } from '../../../helpers/resize';
import { ReactComponent as CogIcon } from '../../../assets/icons/cog.svg';
import { ReactComponent as GraphIcon } from '../../../assets/icons/graph.svg';
import { ReactComponent as PeopleIcon } from '../../../assets/icons/people.svg';
import { ReactComponent as EditIcon } from '../../../assets/icons/edit.svg';
import styles from './pluses.module.scss';

const SCROLL_ELEMENTS = ['#sec-1', '#sec-2', '#sec-3', '#sec-4'];

const TRIGGERED_ID = '#pluses-inner';
const PINNED_PAGINATION = '#pinned-pag';
const PINNED_TITLE = '#plus-title';

const PLUSES_CAROUSEL = [
    {
        key: 1,
        title: 'Не только продукт, но и команда разработчиков',
        text: 'Мы — команда IT-специалистов с опытом работы в ритейле. Поэтому  можем настроить интеграцию с' +
            ' вашими системами и добавить на платформу любые дополнительные функции, настройки и чек-листы под' +
            ' ваши задачи.'
    },
    {
        key: 2,
        title: 'Можно настроить под свои бизнес-процессы',
        text: 'Соберите под себя чек-лист для визита на торговую точку, систему отчётности или аудита, загрузив' +
            ' любую структуру вопросов для проверки. В рабочем пространстве будут только те показатели и поля,' +
            ' которые вам нужны.'
    },
    {
        key: 3,
        title: 'Платформа меняется вместе с рынком',
        text: 'Мы постоянно следим за обновлением законов для ритейла, правилами главных торговых сетей и ' +
            'дорабатываем WODO с учётом всех важных изменений.'
    },
    {
        key: 4,
        title: 'Бесплатный период и помощь на старте',
        text: 'Мы понимаем, что для изучения нового софта нужно время. Поэтому  дадим пробный доступ со' +
            ' всеми возможностям платформы, поможем сотрудникам разобраться в функционале и настроим платформу' +
            ' под ваш бизнес.'
    },
];

const CarouselItem = React.memo(({ title, text}) => {
    return (
        <div className={styles.carouselItem}>
            <span className={styles.itemTitle}>{title}</span>
            <p className={styles.itemText}>{text}</p>
        </div>
    )
});

const Pluses =  React.memo(() => {
    const [x] = useWindowSize();
    const showScrollable = x >= TABLET_PX;
    const loaded = useContext(AppContext);
    const activeStyle = styles.iconActive;

    const scrollTrigger = {
        trigger: TRIGGERED_ID,
        start: 'top center-=138px',
        end: 'bottom bottom',
        pin: PINNED_PAGINATION,
        scrub: 2,
    };

    const renderIndicators = (onClickHandler, isSelected, index) => {
        return (
            <li
                tabIndex={0}
                key={index}
                onClick={onClickHandler}
                className={isSelected ? styles.indicatorActive : styles.indicatorItem}
            />
        );
    };

    useEffect(() => {
        if (loaded && isDesktop) {
            initAnimation();
        }
    }, [loaded]);

    const initAnimation = () => {
        gsap.to(TRIGGERED_ID, { scrollTrigger });
        gsap.to(TRIGGERED_ID, { scrollTrigger: { ...scrollTrigger, pin: PINNED_TITLE } });

        SCROLL_ELEMENTS.forEach((el) => {
            gsap.timeline({
                scrollTrigger: {
                    trigger: el,
                    start: 'top top',
                    end: 'bottom bottom',
                    pin: `${el} div`,
                    scrub: 2,
                },
            });

            gsap.timeline({
                scrollTrigger: {
                    trigger: el,
                    start: 'top center',
                    end: 'bottom center',
                    onEnter: () => toggleClass(el, true),
                    onLeave: () => toggleClass(el),
                    onEnterBack: () => toggleClass(el, true),
                    onLeaveBack: () => toggleClass(el),
                },
            });

            gsap.from(`${el}-1-1`, {
                scrollTrigger: {
                    trigger: el,
                    start: 'top center',
                    end: '+=400',
                    scrub: 2,
                    toggleActions: 'restart pause reverse pause',
                },
                opacity: 0,
                y: 200,
            });

            if (el !== '#sec-4') {
                gsap.to(`${el}-1`, {
                    scrollTrigger: {
                        trigger: el,
                        start: 'center+=200 center',
                        end: '+=600',
                        scrub: 2,
                        toggleActions: 'restart pause reverse pause',
                    },
                    opacity: 0,
                });
            }
        });
    };
    
    const goTo = (id) => {
        gsap.to(window, { duration: 1, scrollTo: id });
    };

    const toggleClass = (el, doActive) => {
        gsap.utils.toArray('#pinned-pag div').forEach((elem) => {
            if (elem.dataset.for === el) {
                if (doActive) {
                    elem.classList.add(activeStyle)
                } else {
                    elem.classList.remove(activeStyle)
                }
            }
        })
    };

    return (
        <section id="pluses" className={styles.pluses}>
            <div id="pluses-inner" className={styles.plusesInner}>
                <h2 id="plus-title" className={`${styles.sectionTitle} ${!isDesktop ? 'fade-in' : ''}`}>
                    Какие плюсы
                    <div className={`${styles.ellipse} circle-path`} />
                </h2>
                {showScrollable ? (
                    <>
                        <div className={styles.scrollContentWrapper}>
                            <div className={styles.scrollContent}>
                                <div id="sec-1" className={styles.animationWrapper}>
                                    <div id="sec-1-1" className={styles.itemsWrapper}>
                                        <div id="sec-1-1-1" className={styles.itemInner}>
                                            <h3>Можно настроить под свои бизнес-процессы</h3>
                                            <p>
                                                Соберите под себя чек-лист для визита на торговую точку,
                                                систему отчётности или аудита, загрузив любую структуру вопросов
                                                для проверки. В рабочем пространстве будут только те показатели
                                                и поля, которые вам нужны.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div id="sec-2" className={styles.animationWrapper}>
                                    <div id="sec-2-1" className={styles.itemsWrapper}>
                                        <div id="sec-2-1-1"  className={styles.itemInner}>
                                            <h3>Платформа меняется вместе с рынком</h3>
                                            <p>
                                                Мы постоянно следим за обновлением законов для ритейла, правилами
                                                главных торговых сетей и дорабатываем WODO с учётом всех важных изменений.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div id="sec-3" className={styles.animationWrapper}>
                                    <div id="sec-3-1" className={styles.itemsWrapper}>
                                        <div id="sec-3-1-1" className={styles.itemInner}>
                                            <h3>Не только продукт, но и команда разработчиков</h3>
                                            <p>
                                                Мы — команда IT-специалистов с опытом работы в ритейле.
                                                Поэтому  можем настроить интеграцию с вашими системами и
                                                добавить на платформу любые дополнительные функции, настройки
                                                и чек-листы под ваши задачи.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div id="sec-4" className={styles.animationWrapper}>
                                    <div id="sec-4-1" className={styles.itemsWrapper}>
                                        <div id="sec-4-1-1" className={styles.itemInner}>
                                            <h3>Бесплатный период и помощь на старте</h3>
                                            <p>
                                                Мы понимаем, что для изучения нового софта нужно время.
                                                Поэтому дадим пробный доступ со всеми возможностям платформы,
                                                поможем сотрудникам разобраться в функционале и настроим платформу под ваш бизнес.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={styles.paginationBlock}>
                            <div id="pinned-pag" className={`${styles.paginationInner}`}>
                                <div
                                    data-for="#sec-1"
                                    onClick={() => goTo('#sec-1')}
                                    className={`${styles.iconWrapper} ${styles.cogWrapper}`}
                                >
                                    <CogIcon/>
                                </div>
                                <div
                                    data-for="#sec-2"
                                    onClick={() => goTo('#sec-2')}
                                    className={`${styles.iconWrapper} ${styles.graphWrapper}`}
                                >
                                    <GraphIcon/>
                                </div>
                                <div
                                    data-for="#sec-3"
                                    onClick={() => goTo('#sec-3')}
                                    className={`${styles.iconWrapper} ${styles.peopleWrapper}`}
                                >
                                    <PeopleIcon/>
                                </div>
                                <div
                                    data-for="#sec-4"
                                    onClick={() => goTo('#sec-4')}
                                    className={`${styles.iconWrapper} ${styles.editWrapper}`}
                                >
                                    <EditIcon/>
                                </div>
                            </div>
                        </div>
                    </>
                ) : (
                    <Carousel
                        emulateTouch
                        swipeable
                        infiniteLoop
                        renderArrowPrev={() => <div/>}
                        renderArrowNext={() => <div/>}
                        showThumbs={false}
                        showStatus={false}
                        renderIndicator={renderIndicators}
                    >
                        {PLUSES_CAROUSEL.map((el) => (
                            <CarouselItem
                                key={el.key}
                                title={el.title}
                                text={el.text}
                            />
                        ))}
                    </Carousel>
                )}
            </div>
        </section>
    )
});

export default Pluses;
