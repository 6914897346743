import React, { useLayoutEffect, useState } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import { SplitText } from 'gsap/SplitText';
import { ScrollSmoother } from 'gsap/ScrollSmoother';
import { AppContext } from './helpers/context';
import { isDesktop } from './helpers/constants';
import Header from './components/Header';
import Main from './components/Main';
import Footer from './components/Footer';
import FooterMobile from './components/FooterMobile';
import Popup from './components/Popup';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import './styles/app.scss';

gsap.registerPlugin(ScrollTrigger, ScrollToPlugin, ScrollSmoother, SplitText);

ScrollTrigger.defaults({ toggleActions: 'restart none none reset' });

if (isDesktop) {
    ScrollTrigger.normalizeScroll(true);
}

function App() {
    const [formOpened, setFormOpened] = useState(false);
    const [navOpened, setNavOpened] = useState(false);
    const [loaded, setLoaded] = useState(false);

    useLayoutEffect(() => {
        startAnimations();
    });

    const startAnimations = () => {
        if (!loaded) {
            setLoaded(true);
        }
    };

    const toggleNavDropdown = () => {
        setNavOpened(!navOpened);
    };

    const togglePopup = () => {
        setFormOpened(!formOpened);
    };

    return (
        <div className="app">
            <AppContext.Provider value={loaded}>
                <Header onOpenDropdown={toggleNavDropdown} />
                <div id="wrapper">
                    <div id="content" className="app-inner">
                         <Main navOpened={navOpened} onClickAction={togglePopup} />
                        {isDesktop && <Footer onClickAction={togglePopup} />}
                    </div>
                </div>
                {!isDesktop && <FooterMobile navOpened={navOpened} />}
                {formOpened && <Popup togglePopup={togglePopup} />}
            </AppContext.Provider>
        </div>
    );
}

export default App;
